import { urlCurator } from './event.utils';
import store from '../../../store';

export class GeneralEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  static notificationEventProcessing(notification) {
    return {
      attachedObjectId: notification.attachedObjectId,
      authorId: notification.authorId,
      id: notification.id,
      message: notification.message,
      tags: notification.tags,
      title: notification.title,
      createdAt: notification.createdAt,
    };
  }

  async filter(filter, url, origin = 'WEB_PARAMETRIC') {
    // This is deprecated, and should be replaced by content.search
    const event = filter;
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'GENERAL_FILTER',
      event,
      url: urlCurator(url),
    });
  }

  async feedback(feedback, url, origin = 'WEB_PARAMETRIC') {
    const event = { feedback };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'GENERAL_FEEDBACK',
      event,
      url: urlCurator(url),
    });
  }

  async contribution(contribution, url, origin = 'WEB_PARAMETRIC') {
    const event = { contribution };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'GENERAL_CONTRIBUTION',
      event,
      url: urlCurator(url),
    });
  }

  async notification(notification, url, origin = 'WEB_PARAMETRIC') {
    const event = {
      notification: this.constructor.notificationEventProcessing(notification),
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'GENERAL_NOTIFICATION',
      event,
      url: urlCurator(url),
    });
  }

  async adminSectionView(section, source, origin = 'WEB_PARAMETRIC') {
    this.updateLoggerAttributes(origin);

    return this.logClient.send({
      topic: 'APP_ADMIN_SECTION_VIEW',
      event: {
        section,
        source,
      },
    });
  }
}
