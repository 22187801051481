export default async function (route, store) {
  let source = 'NAVIGATION';

  const routeMappings = {
    '/dashboard/analytics-contents': 'DASHBOARD',
    '/settings/users/invitations': 'USERS_INVITATIONS',
    '/settings/users/administrate': 'USERS_ADMINISTRATE',
  };

  const key = Object.keys(routeMappings).find((prefix) =>
    route.path.startsWith(prefix),
  );

  if (!key) return null;

  const section = routeMappings[key];
  const queryFrom = route.query.from;

  if (!queryFrom) {
    await store.dispatch('pageView', { section, source });
    return null;
  }

  await store.dispatch('pageView', { section, source: queryFrom });

  const queryWithoutUnwanted = { ...route.query, from: undefined };

  return {
    path: route.path,
    query: queryWithoutUnwanted,
  };
}
